import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import cn from 'classnames';
import React from 'react';

import type { SelectOptionType } from 'components/complex/forms/ApplicationForm/types';

import ALink from '../../../basic/ALink';

import s from './ApplicationForm.module.scss';

export const values: SelectOptionType[] = [
  { value: '1', label: 'Телефон' },
  { value: '2', label: 'WhatsApp' },
  { value: '3', label: 'Telegram' },
  { value: '4', label: 'Email' },
];
export const recaptchaApiKey = '6LesiMIgAAAAACOqcL-eRNY3xLpGztchfESHHt9k';

export const agreementText = (
  <Typograf>
    Нажимая кнопку «Отправить» вы подтверждаете, что согласны на&nbsp;
    <ALink
      className={s.link}
      href="https://www.a-3.ru/files/politika_obrabotki_i_zashiti_dannih.pdf"
      target="_blank"
    >
      обработку персональных данных
    </ALink>{' '}
    и соглашаетесь получать информацию о предложениях в соответствии с&nbsp;
    <ALink
      className={cn(s.link, s.agreementLink)}
      href="https://www.a-3.ru/files/soglasie_na_rassylku_a_3.pdf"
      target="_blank"
    >
      Правилами рассылок ООО "Платёжный сервис А3"
    </ALink>
  </Typograf>
);
